import axios from "axios";
import { useCallback } from "react";

export default function useAxios() {

    const apiRequest = useCallback(async(config) => {
        const { url, method, data, headers } = config;

        if (!url) {
            throw new Error("URL is required");
        }

        return await axios({
            url,
            method: method || "GET",
            data: data || null,
            headers: headers || null,
        });
    }, []);

    return apiRequest;
}
