import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import { routes } from "./config/routes.config";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: routes
  }
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;
