import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import { API_URL,API_ROUND_DETAILS } from 'constants/api.constant';
import RoundHeader from 'components/RoundHeader';
import OverviewRound from 'components/OverviewRound';


export default function Details() {
    const { id } = useParams();
    const { data, loading } = useFetch(`${API_ROUND_DETAILS}?round_id=${id}`);
    const [round, setRound] = useState(null);

    useEffect(() => setRound(data[0]), [data]);

    if (loading || !round) return <p>Loading...</p>

    return (
        <>
            <OverviewRound round={round} />
            <RoundHeader />
            <Outlet context={round} />
        </>
    )
}
