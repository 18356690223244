import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/Loading";
import Select from "react-select";
import { FetchParticipants } from "./FetchParticipants";
import { API_preincidentes } from "constants/api.constant";

export default function ImportIncidentes() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [incidentesData, setIncidentesData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterDescription, setFilterDescription] = useState("");
  const [showIncidentesOnly, setShowIncidentesOnly] = useState(false);
  const [showUnimportedOnly, setShowUnimportedOnly] = useState(true);

  const [parcfermeData, setParcfermeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uniqueInfringementDescriptions, setUniqueInfringementDescriptions] =
    useState([]);
  const [participants, setParticipants] = useState([]);
  const [guiltyValue, setGuiltyValue] = useState({});
  const [victimValue, setVictimValue] = useState({});
  const [roundDetails, setRoundDetails] = useState({});
  const [selectedVictims, setSelectedVictims] = useState({});
  const [selectedGuilty, setSelectedGuilty] = useState({});

  useEffect(() => {
    setLoading(true);

    fetch(`${API_preincidentes}?round_id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setIncidentesData(data);
        setLoading(false);
        console.log("IncidentesData: ", data);

        const uniqueDescriptions = [
          ...new Set(data.map((incident) => incident.infringementDescription)),
        ];
        setUniqueInfringementDescriptions(uniqueDescriptions);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    fetch(
      `https://restapi.gpesportsrd.com/api/v1/external/getrounds?round_id=${id}`
    )
      .then((response) => response.json())
      .then((data) => {
        const roundData = data[0];
        setRoundDetails({
          competition_id: roundData.competition_id,
          eventchannelID: roundData.eventchannelID,
          competition_name: roundData.competition_name,
          discord_viprole_id: roundData.discord_viprole_id,
          projectname: roundData.projectname,
          project_id: roundData.project_id,
          round_id: roundData.round_id,
          track_id: roundData.track_id,
          track_name: roundData.track_name,
          start_date: roundData.start_date,
          days_ago: roundData.days_ago,
          incidentnickname: roundData.incidentnickname,
        });
        console.log("roundData", roundData);

        FetchParticipants(roundData.project_id).then(
          (formattedParticipants) => {
            setParticipants(formattedParticipants);
            console.log("formattedParticipants", formattedParticipants);
          }
        );
      })
      .catch((error) => {
        console.error("Error fetching participants:", error);
      });

    fetch(`https://api4.gpesportsrd.com/parcferme?round_id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && data.data.length > 0) {
          const parcfermeDataMap = {};

          // Iterate through the data and store rows by individualid or userid
          data.data.forEach((entry) => {
            if (entry.individualid) {
              parcfermeDataMap[entry.individualid] = entry;
            } else if (entry.userid) {
              parcfermeDataMap[entry.userid] = entry;
            }
          });

          setParcfermeData(parcfermeDataMap);
          console.log("ParcfermeData", parcfermeData);
          console.log("ParcfermeData array", Object.values(parcfermeData));
        } else {
          console.error("No parcferme data found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching parcferme data:", error);
      });
  }, [id]);

  const handleCheckboxChange = (incidentId, isChecked) => {
    if (isChecked) {
      setSelectedRows([...selectedRows, incidentId]);
      console.log("selected rows", selectedRows);
      const preincident = incidentesData.find((inc) => inc.id === incidentId);
      console.log("preincident", preincident);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== incidentId));
    }
  };

  const handleImport = () => {
    selectedRows.forEach((incidentId) => {
      const selectedIncident = incidentesData.find(
        (inc) => inc.id === incidentId
      );
      if (!selectedIncident) return;
      const lapAsString = selectedIncident.udp_lapNum.toString();
      const tiempoAsString = selectedIncident.udp_m_sessionTime.toString();

      let guiltyId;
      if (selectedGuilty[incidentId]?.individual_id) {
        guiltyId = selectedGuilty[incidentId]?.individual_id;
        console.log("Using guilty individual_id from dropdown:", guiltyId);
      } else {
        guiltyId = selectedIncident.tracks_individual_id;
        console.log("Using guilty  from preincidents_otherindividual_id:", guiltyId);
      }
      
      let victimId;
      console.log("victim individual_id from dropdown:", selectedVictims[incidentId]?.individual_id);
      console.log("victim from preincidents_individual_id:", selectedIncident.tracks_otherindividual_id);
      if (selectedVictims[incidentId]?.individual_id) {
        victimId = selectedVictims[incidentId]?.individual_id;
        console.log("Using victim individual_id from dropdown:", victimId);
      } else if (selectedIncident.tracks_otherindividual_id) {
        victimId = selectedIncident.tracks_otherindividual_id;
        console.log("Using victim from preincidents_individual_id:", victimId);
      } else {
        victimId = guiltyId;
        console.log("Using guiltyId as victim already defined:", victimId);
      }
      
      
      const victim = participants.find((participant) => participant.individual_id === victimId);
      const guilty = participants.find((participant) => participant.individual_id === guiltyId);

      let afectado = ""; // Define afectado variable
      let reportado = "";
      
      if (roundDetails.incidentnickname === 0) {
        afectado = `${victim.first_name} ${victim.last_name}`;
        reportado = `${guilty.first_name} ${guilty.last_name}`;
      } else {
        afectado = `${victim.nickname}`;
        reportado = `${guilty.nickname}`;
      }
      

      const parcfermeEntry =
        Object.values(parcfermeData).find(
          (entry) => entry.individualid === victimId
        ) || {};
      // console.log("selectedVictimId", selectedVictimId);
      // console.log("selectedGuiltyId", selectedGuiltyId);
      console.log("ParcfermeEntry", parcfermeEntry);

   
      // FOR FUTURE USE: Get timestamp from parcferme (Fecha field)
      const timestampParcferme = parcfermeEntry.Fecha || "";
      console.log("timestampParcferme", timestampParcferme);

      const videoUrl = parcfermeEntry.Youtube1 || "";
      const minTime = parcfermeEntry.min_time || "0";

      const sessionTime = parseInt(selectedIncident.udp_m_sessionTime) + parseInt(minTime);
      console.log("minTime", minTime);
      console.log("sessiontime", selectedIncident.udp_m_sessionTime);
      console.log("sessiontime", sessionTime);
      const payload = {
        //fpenalty_type: selectedIncident.udp_penaltyType,
        Fecha: roundDetails.start_date,
        Categoria: roundDetails.projectname,
        Resolucion: "",
        incidentes_type: selectedIncident.udp_infringementType,
        Resumen: `${selectedIncident.infringementDescription} - (${selectedIncident.penaltyDescription})`,


        Afectado: afectado,
        Reportado: reportado,
        guilty_individual_id: guilty.individual_id,
        guilty_user_id: guilty.user_id,
        victim_individual_id: victim.individual_id,
        victim_user_id: victim.user_id,

     
        Lap: lapAsString,
        Tiempo: tiempoAsString,
        eventID: selectedIncident.event_id,
        project_id: roundDetails.project_id,
        round_id: roundDetails.round_id,
        trackid: roundDetails.track_id,
        Pista: roundDetails.track_name,
        Auth: "UDP-" + selectedIncident.raceId,
        row_status: selectedIncident.id.toString(),
        sessionTime: sessionTime,
        videop: videoUrl,
      };
      console.log("payload", payload);

      fetch("https://api4.gpesportsrd.com/incidentes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((createdIncident) => {
          if (createdIncident) {
            console.log("Incident imported successfully:", createdIncident);

            // display a success message
            alert("Incident imported successfully.");
            navigate(`/rounds/${id}/incidentes`);

            // Add a separate console log for the ID
            console.log("Incident ID:", createdIncident.data.id);
            // console.log('Incident ID:', createdIncident.id);

            fetch(`${API_preincidentes}/${selectedIncident.id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                imported: createdIncident.data.id,
              }),
            })
              .then((response) => {
                if (response.ok) {
                  console.log(
                    "Preincident updated successfully with imported incident ID."
                  );
                } else {
                  console.error(
                    "Error updating preincident with imported incident ID."
                  );
                }
              })
              .catch((error) => {
                console.error("Error updating preincident:", error);
              });
          } else {
            throw new Error("Incident creation failed");
          }
        })
        .catch((error) => {
          console.error(
            "Error importing incident:",
            selectedIncident.infringementDescription,
            error
          );
        });
    });
  };

  const parseParticipantLabel = (participant) => {
    return `${participant.first_name} ${participant.last_name} (${participant.Eagame})`;
  };

  const guiltyOptions = participants.map((participant) => ({
    value: participant.user_id,
    label: parseParticipantLabel(participant),
    individual_id: participant.individual_id,
    ...participant,
  }));

  const victimOptions = participants.map((participant) => ({
    value: participant.user_id,
    label: parseParticipantLabel(participant),
    individual_id: participant.individual_id,
    ...participant,
  }));

  const filteredIncidentesData = incidentesData.filter((incident) => {
    return (
      (filterDescription === "" ||
        incident.infringementDescription.includes(filterDescription)) &&
      (!showIncidentesOnly ||
        (incident.udp_m_name && incident.udp_other_m_name)) &&
      (!showUnimportedOnly || incident.imported === 0)
    );
  });

  if (loading) return <Loading />;

  return (
    <>
      <div className="flex justify-between items-center mb-6 overflow-hidden">
        <h2 className="text-2xl font-bold">Import Incidentes</h2>
      </div>

      <div className="mb-4">
        <label className="mr-2">Filter Infringements:</label>
        <select
          value={filterDescription}
          onChange={(e) => setFilterDescription(e.target.value)}
        >
          <option value="">All</option>
          {uniqueInfringementDescriptions.map((description) => (
            <option key={description} value={description}>
              {description}
            </option>
          ))}
        </select>

        <label className="ml-4">
          <input
            type="checkbox"
            checked={showIncidentesOnly}
            onChange={() => setShowIncidentesOnly(!showIncidentesOnly)}
          />
          Only Incidents
        </label>
        <label className="ml-4">
  <input
    type="checkbox"
    checked={showUnimportedOnly}
    onChange={() => setShowUnimportedOnly(!showUnimportedOnly)}
  />
  Pending Import
</label>
      </div>

      <table className="border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300"></th>
            <th className="border border-gray-300">ID</th>
            <th className="border border-gray-300">ST</th>
            <th className="border border-gray-300">Lap</th>
            <th className="border border-gray-300">Tiempo</th>
            <th className="border border-gray-300">Incidente</th>
            <th className="border border-gray-300">Penalidad</th>
            <th className="border border-gray-300">Victim EA</th>
            <th className="border border-gray-300">Victim</th>
            <th className="border border-gray-300">Guilty EA</th>
            <th className="border border-gray-300">Guilty</th>
          </tr>
        </thead>
        <tbody>
          {filteredIncidentesData.map((incident, index) => {
            const defaultVictimValue = victimOptions.find(
              (option) =>
                option.individual_id === incident.tracks_otherindividual_id
            );

            const defaultGuiltyValue = guiltyOptions.find(
              (option) => option.individual_id === incident.tracks_individual_id
            );

            const isCheckboxDisabled = incident.imported > 0;

            return (
              <tr key={index}>
      <td className="border border-gray-300">
        <input
          type="checkbox"
          checked={selectedRows.includes(incident.id)}
          onChange={(e) =>
            handleCheckboxChange(incident.id, e.target.checked)
          }
          disabled={isCheckboxDisabled}
          style={{ opacity: isCheckboxDisabled ? 0.5 : 1 }}
        />
      </td>
                <td className="border border-gray-300">{incident.id}</td>
                <td className="border border-gray-300">
                  {incident.sessionType}
                </td>
                <td className="border border-gray-300">
                  {incident.udp_lapNum}
                </td>
                <td className="border border-gray-300">
                  {Math.floor(incident.udp_m_sessionTime).toFixed(0)}
                </td>
                <td className="border border-gray-300">
                  {incident.infringementDescription}
                </td>
                <td className="border border-gray-300">
                  {incident.udp_time !== 255
                    ? `${incident.penaltyDescription} (${incident.udp_time} Segundos)`
                    : incident.penaltyDescription}
                </td>
                <td className="border border-gray-300">
                  {incident.udp_other_m_name}
                </td>
                <td className="border border-gray-300">
                <Select
  options={victimOptions}
  value={selectedVictims[incident.id] || defaultVictimValue}
  onChange={(selectedOption) => {
    setSelectedVictims({
      ...selectedVictims,
      [incident.id]: selectedOption,
    });
  }}
  isSearchable
/>
                </td>
                <td className="border border-gray-300">
                  {incident.udp_m_name}
                </td>
                <td className="border border-gray-300">
                <Select
  options={guiltyOptions}
  value={selectedGuilty[incident.id] || defaultGuiltyValue}
  onChange={(selectedOption) => {
    setSelectedGuilty({
      ...selectedGuilty,
      [incident.id]: selectedOption,
    });
  }}
  isSearchable
/>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="mt-4">
        <button
          className="text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5"
          onClick={handleImport}
        >
          Import Selected
        </button>
      </div>
    </>
  );
}
