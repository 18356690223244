import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL, API_URL3 } from 'constants/api.constant';
import Loading from 'components/Loading';
import useFetch from 'hooks/useFetch';
import TabsResultados from './TabsResultados';
import ResultadosFinalesList from './ResultadosFinalesList';

export default function Resultados() {
  const { id: roundId } = useParams();
  const { data, loading } = useFetch(`${API_URL}/v1/external/getevents?projectround_id=${roundId}`);
  const [selectedEvent, setSelectedEvent] = useState(data[0]);
  const [saving, setSaving] = useState(false);

  const onSaveResults = async (results) => {
    // Implementation of onSaveResults remains the same
  }

  const onChange = (event) => {
    if (event.id === selectedEvent?.id) return;
    setSelectedEvent(event);
  }

  useEffect(() => {
    setSelectedEvent(data[0]);
  }, [data]);

  if (loading) return <Loading />

  return (
    <div>
      <div className='mb-4'>
        <div className='flex gap-4'>
          <div className='flex items-center'>
            <h2 className='text-2xl font-bold'>Eventos</h2>
          </div>
          <TabsResultados data={data} selectedEvent={selectedEvent} onChange={onChange} />
        </div>
      </div>
      <div>
        {selectedEvent?.id ? (
          <ResultadosFinalesList eventId={selectedEvent?.id} onSave={onSaveResults} saving={saving} />
        ) : (
          <div className='flex items-center justify-center'>
            <p className='text-gray-500 italic'>Seleccione un evento</p>
          </div>
        )}
      </div>
    </div>
  );
}
