import React, { useCallback, useEffect, useState } from 'react'
import Table, { LoadingTableContent, NoDataRow, TBody, Td, Th, THead, Tr } from 'components/Table';
import classNames from 'classnames';
import { BiLoaderAlt } from 'react-icons/bi';
import DropdownIndividual from './DropdownIndividual';
import DropdownTeams from './DropdownTeams';
import { convertSecondsToTimestamp } from 'utils';
import { API_URL, API_URL2 } from 'constants/api.constant';
import useAxios from 'hooks/useAxios';
import { useOutletContext } from 'react-router-dom';
import getBonusPoints from './bonus-point';
import * as apigp from 'constants/api.constant';


const InputPoints = ({ points, ...rest }) => {
    return (
        <input
            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-12 p-2 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
            type='number'
            {...rest}
            min={0}
        />
    )
}


const participantUrl = `${API_URL}/v1/external/getparticipants`;
const teamUrl = `${API_URL2}/teams.php`;

export default function ResultsList({ data, loading, onImportResults, importing, eventId }) {
    console.log("Data received in ResultsList:", eventId);
    const apiRequest = useAxios();
    const round = useOutletContext();
    const [results, setResults] = useState([])
    const [isCheckAll, setIsCheckAll] = useState(true);
    const [filter, setFilter] = useState('')
    const [participants, setParticipants] = useState([])
    const [teams, setTeams] = useState([])
    const [loadinResults, setLoadingResults] = useState(loading)
    const [selectedFile, setSelectedFile] = useState(null); // State for the selected file


    const loadResults = async() => {
        setLoadingResults(true)
        // Se cargan los participantes de acuerdo al projectId del round
        const participants = await getParticipants(round.project_id);
        // console.log("Participants", participants)

        const resultsWithIndividual = data.map(x => ({
            ...x,
            individual: participants.find(value => value.individual_id?.toString() === x.trackIndividualId?.toString()) || null
        }))

        // Se cargan los teams
        const teams = await getTeams()

        const resultsWithTeams = resultsWithIndividual.map(x => ({
            ...x,
            team: teams.find(value => value.id.toString() === x.individual?.team_id?.toString()) || null
        }))

        const resultsChecked = resultsWithTeams.map(x => ({
            ...x,
            checked: x.is_inserted !== "0" ? false: true
        }))

        // Llamar de forma asíncrona a la función que trae los bonus points
        const fetchBonusPoints = async () => {
            try {
                const data = await getBonusPoints(resultsChecked);
                return data;
            } catch (error) {
                console.log(error);
            }
        };
        
        
        let newResults;
        // Esperar a que resultsChecked tenga un valor para usar este para llamar a fetchBonusPoints()
        if (resultsChecked.length > 0) {
            newResults = await fetchBonusPoints();
        }

        setParticipants(participants)
        setTeams(teams)
        // Esperar a que newResults tenga un valor para asignar el valor a results
        if (newResults !== undefined) {
            setResults(newResults)
            const updatedResults = newResults.map(result => {
                if (result.totalRaceTime === null || result.totalRaceTime === '0' || result.totalRaceTime === '') {
                    return { ...result, checked: false };
                }
                return result;
            });
            setResults(updatedResults)
        }
        setLoadingResults(false)
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
    
        const formData = new FormData();
        formData.append("file", file);
        
        // Append eventId to the formData if it's available
        if (eventId) {
            formData.append("event_id", eventId.id);
        }
    
        try {
            const response = await fetch(`${apigp.URL4}/${apigp.csvupload}`, {
                method: 'POST',
                body: formData,
            });
    
            if (response.ok) {
                const result = await response.json();
                console.log(result);
                alert("File uploaded successfully!");
                loadResults(); // Call loadResults after successful upload
            } else {
                const errorResult = await response.json();
                console.error("Error response:", errorResult);
                alert("Failed to upload file.");
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Error uploading file.");
        }
    };
    





    const onChangeResult = (value, id, name) => {
        setResults((prevResults) => {
            const newResults = [...prevResults];
            const index = newResults.findIndex((result) => result.id === id);
            newResults[index] = { ...newResults[index], [name]: value };
            console.log(newResults[index])
            return newResults;
        });
    }


    const onCheckAll = (checked) => {
        setResults((prevResults) => {
            const newResults = [...prevResults];
            for (let index = 0; index < newResults.length; index++) {
                if (newResults[index].is_inserted !== "0") {
                    continue
                }
                newResults[index] = { ...newResults[index], checked: checked };
            }
            return newResults;
        });
        setIsCheckAll(checked)
    }

    const onChangeIndividual = (value, id) => {
        setResults((prevResults) => {
            const newResults = [...prevResults];
            const index = newResults.findIndex((result) => result.id === id);
            newResults[index] = { ...newResults[index], individual: value };
            newResults[index] = { ...newResults[index], team: teams.find(x => x.id?.toString() === value?.team_id?.toString()) };
            return newResults;
        });
    }

    const handleImportResults = () => {
        const resultsToImport = results.filter((result) => result.checked).map((result) => ({
            ...result,
            trackIndividualId: result.individual?.individual_id || null,
            teamId: result.team?.id || null,
            pilotnumber: result.individual?.pilotnumber || null,
            userId: result.individual?.user_id
        }));
        onImportResults(resultsToImport);
    }

    const filteredData = (data, filtro) => {
        const newData = data.filter(x =>
            x.nickName?.toLowerCase()?.includes(filtro?.toLowerCase()) ||
            x.raceName?.toLowerCase()?.includes(filtro?.toLowerCase()) ||
            x.vehicleName?.toLowerCase()?.includes(filtro?.toLowerCase())
        );
        return newData;
    }

    // OPTIMIZATION
    const getParticipants = useCallback(async (projectId) => {
        try {
            const { data } = await apiRequest({ url: `${participantUrl}?project_id=${projectId}` })
            const participants = data;
            return participants
        } catch (error) {
            console.error(error);
            return []
        }
    }, [apiRequest]);

    const getTeams = useCallback(async () => {
        try {
            const { data } = await apiRequest({ url: `${teamUrl}` })
            return data;
        } catch (error) {
            console.error(error);
            return []
        }
    }, [apiRequest]);

    const getPoints = (points) => {
        if (points === null || points === undefined) {
            return ''
        }
        return points
    }

    useEffect(() => {

        loadResults()
        // setResults(data);
    }, [data, getParticipants, getTeams, round.project_id])

    // useEffect(() => {
    //     console.log('Results', results)
    // }, [results])

    return (
        <>
        <div className='flex justify-between mb-4'>
            <h2 className='text-2xl font-bold'>Importar Resultados</h2>
            <div className='flex items-center gap-4'>
                <input
                    type='search'
                    className='border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
                    placeholder='Buscar'
                    value={filter}
                    onChange={({target}) => setFilter(target.value)}
                />
                <div className='min-w-max'>

<input
    type="file"
    onChange={handleFileChange}
    style={{ display: 'none' }}
    id="file-upload"
/>
<label htmlFor="file-upload" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer">
    Subir iRacing 
</label>
</div>
                <div className='min-w-max'>
                    <button
                        className='bg-transparent hover:bg-blue-600 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-600 hover:border-transparent rounded disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
                        onClick={handleImportResults}
                        disabled={importing}
                    >
                        <div className='flex gap-2 items-center'>
                            { importing && (<BiLoaderAlt className={classNames({ 'animate-spin': importing })} />)}
                            { importing ? 'Importando resultados...' : 'Importar resultados'}
                        </div>
                    </button>
                    {/* <input
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="file-upload"
                    />
                    <label htmlFor="file-upload" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer">
                        Subir Archivo
                    </label> */}
                </div>

            </div>
        </div>
        <Table id='tabla-incidentes'>
            <THead>
                <Tr>
                    <Th>
                        <input className='cursor-pointer' type='checkbox' onChange={({ target }) => onCheckAll(target.checked)} checked={isCheckAll} />
                    </Th>
                    <Th>POS</Th>
                    <Th>Final Participant</Th>
                    <Th>Original Driver</Th>
                    <Th>Team ID</Th>
                    <Th>Lap</Th>
                    <Th>Fastest Lap</Th>
                    <Th>Total RTime</Th>
                    <Th>Bonus</Th>
                    <Th>Status</Th>
                </Tr>
            </THead>
            <TBody>
                <LoadingTableContent loading={loadinResults} colSpan={10} />
                {filteredData(results, filter).map((result, index) => {
                    // console.log(result)
                    // getBonusPoints(result.roundId, result.eventID);
                    return (
                    <Tr key={index} className='hover:bg-gray-100' style={{backgroundColor: result.totalRaceTime ? '' : '#FDDADA'}}>
                        <Th>
                            <input className='cursor-pointer disabled:cursor-not-allowed' type='checkbox' onChange={({ target }) => onChangeResult(target.checked, result.id, 'checked')} checked={result.checked} disabled={result.is_inserted !== "0"} />
                        </Th>
                        <Td>{result.position}</Td>
                        <Td>
                            <DropdownIndividual value={result?.individual?.individual_id} options={participants} onChange={(opt) => onChangeIndividual(opt, result.id)} />
                        </Td>
                        <Td>{result.vehicleName}</Td>
                        <Td>
                            {/* {selectedIndividual[result.id]?.entireObject?.team_id || 'N/A'} */}
                            {/* {selectedTeam[result.id]?.value || 'N/A'} */}
                            <DropdownTeams value={result?.team?.id} options={teams} onChange={(opt) => onChangeResult(opt, result.id, 'team')} />
                        </Td>
                        <Td>{result.numLaps}</Td>
                        <Td>{convertSecondsToTimestamp(result.bestLapTime)}</Td>
                        <Td>{Math.round(parseFloat(result.totalRaceTime || 0) + parseFloat(result.penaltiesTime || 0))}</Td>

                        <Td>
                            <InputPoints value={getPoints(result.bonusPoints)} onChange={({target}) => onChangeResult(target.value, result.id, 'bonusPoints')} />
                        </Td>
                        <Td>{result.is_inserted}</Td>
                    </Tr>
                    )
                })}
                <NoDataRow show={!loadinResults && filteredData(results, filter).length === 0} colSpan={10} />
            </TBody>
        </Table>
        </>
    )
}
