import { API_participants } from "constants/api.constant";

export function FetchParticipants(project_id) {
    return fetch(`${API_participants}?project_id=${project_id}`)
    .then(response => response.json())
    .then(responseData => {
      const formattedParticipants = responseData.map(participant => ({
        participants_id: participant.participants_id,
        competition_name: participant.competition_name,
        competition_id: participant.competition_id,
        eventchannelID: participant.eventchannelID,
        project_id: participant.project_id,
        individual_id: participant.individual_id,
        user_id: participant.user_id,
        last_name: participant.last_name,
        first_name: participant.first_name,
        nickname: participant.nickname,
        team_id: participant.team_id,
        pilotnumber: participant.pilotnumber,
        DiscordID: participant.DiscordID,
        Hear_Rate: participant.Hear_Rate,
        Youtube: participant.Youtube,
        Eagame: participant.Eagame
      }));

      return formattedParticipants;
    });
}
