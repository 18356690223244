import useFetch from "hooks/useFetch";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResultsList from "./ResultsList";
import { API_URL, API_URL2, API_URL3 } from "constants/api.constant";
import TabsResultados from "../ResultadosFinales/TabsResultados";
import useAxios from "hooks/useAxios";
import ModalEvents from "./ModalEvents";

const eventsEndpoint = 'v1/external/getevents';
const resultEndpoint = '/getpreracedetails.php';
const postResultEndpoint = '/results';
const updateResultEndpoint = '/preraceresult';
export default function Results () {
    const { id } = useParams();
    const apiRequest = useAxios();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    // const { data:results, loading } = useFetch(`${API_URL2}${resultEndpoint}?round_id=${id}`);
    const { data, loading:loadingEvents } = useFetch(`${API_URL}/${eventsEndpoint}?projectround_id=${id}`);
    const [selectedEvent, setSelectedEvent] = useState(data[0]);
    const [importing, setImporting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const onChange = (event) => {
        if (event.id === selectedEvent?.id) return;
        setResults([]);
        setSelectedEvent(event);
    }

    const onImportResults = async (values) => {
        const data = values.map((value) => ({
            team_id: parseInt(value.teamId),
            individual_id: parseInt(value?.individual?.individual_id || value.trackIndividualId),
            event_id: parseInt(selectedEvent.id),
            rank: parseInt(value.position),
            performance: value.bestLapTime || 0,
            bonus_points: parseFloat(value.bonusPoints),
            totalracetime: parseFloat(value.totalRaceTime) + parseFloat(value?.penaltiesTime || 0),
            resultstatus: 33,
            laps: value.numLaps,
            comment: null,
            modified_by: 888,
            row_status: value.id,
            id: value.id,
            userId: value?.individual?.user_id || value.userId,
        }));

        const dataRaceID_eventID = values.map((value) => ({
            raceId: value.raceId,
            eventID: parseInt(value.eventID),
        }));


        const dataRaceID_caridx_individualid = values.map((value) => ({
            raceId: value.raceId,
            caridx: parseInt(value.vehicleIdx),
            individual_id: parseInt(value?.individual?.individual_id || value.trackIndividualId),
        }));

        setImporting(true);

        let imported = await importResults(data);
        const dataToUpdate = imported.elementSuccess.map((value) => ({
            id: parseFloat(value.id),
            userId: parseFloat(value.userId),
            is_inserted: 1,
        }));
        let updated = await updatePreRaceResult(dataToUpdate);

        imported = {
            ...imported,
            ...await updateUdpfixEventId(dataRaceID_eventID),
            ...await updateUdpfixCaridx(dataRaceID_caridx_individualid),
        }

        setImporting(false);
        alert(`${imported.sucess} resultados importados correctamente.
            \n${updated.sucess} resultados actualizados correctamente.
            \n${imported.error} errores al importar.
            \n${updated.error} errores al actualizar.
            \n${imported.errors.map((error) => `Detalles del error al importart: ${JSON.stringify(error, null, 2)}`).join('\n')}
            \n${updated.errors.map((error) => `Detalles del error al actualizar: ${JSON.stringify(error, null, 2)}`).join('\n')}
        `);

        await getResults();
    }

    const importResults = async (data) => {
        const imported = {
            sucess: 0,
            error: 0,
            elementSuccess: [],
            errors: []
        }
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            try {
                await apiRequest({ url: `${API_URL3}${postResultEndpoint}`, method: 'POST', data: element });
                imported.sucess++;
                imported.elementSuccess.push(element);
            } catch (error) {
                console.log('ERROR Element: ', element)
                console.error(error);
                imported.errors.push(
                    error.response.data || error.response || error
                );
                imported.error++;
            }
        }

        return imported;
    }

    const updateUdpfixEventId = async (data) => {
        const updated = {
            sucess: 0,
            error: 0,
            elementSuccess: [],
            errors: []
        }

            try {
                const response = await apiRequest({ url: `${API_URL3}/udpfix/eventid`, method: 'POST', data: data[0] });
                console.log('API Request Successful, RESPONSE: ', response);
                updated.sucess++;
                updated.elementSuccess.push(data[0]);
            } catch (error) {
                console.log('ERROR Element: ', data[0])
                console.error(error);
                updated.errors.push(
                    error.response.data || error.response || error
                );
                updated.error++;
            }
        return updated
    }

    const updateUdpfixCaridx = async (data) => {
        const updated = {
            sucess: 0,
            error: 0,
            elementSuccess: [],
            errors: []
        }

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            try {
                const response = await apiRequest({ url: `${API_URL3}/udpfix/caridx`, method: 'POST', data: element });
                console.log('API Request Successful, RESPONSE: ', response);
                updated.sucess++;
                updated.elementSuccess.push(element);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    alert(`Registro no encontrado, el piloto con ID ${element.individual_id} no tuvo ningún choque durante la session`);

                } else {
                console.log('ERROR Element: ', element)
                console.error(error);
                updated.errors.push(
                    error.response.data || error.response || error
                );
                updated.error++;
                }
            }
        }
        return updated
    }

    const updatePreRaceResult = async (data) => {
        const updated = {
            sucess: 0,
            error: 0,
            errors: []
        }

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            try {
                const resp = await apiRequest({ url: `${API_URL3}${updateResultEndpoint}/${element.id}`, method: 'PUT', data: element });
                console.log(resp);
                updated.sucess++;
            } catch (error) {
                console.log('ERROR Element: ', element)
                console.error(error);
                updated.errors.push(
                    error.response.data || error.response || error
                );
                updated.error++;
            }
        }

        return updated;
    }

    const onEditEvent = async (event) => {
        openModal();
    }

    useEffect(() => {
        if (!selectedEvent?.id) {
            setSelectedEvent(data[0]);
        }
    }, [data, selectedEvent?.id]);

    const getResults = useCallback(async () => {
        if (!selectedEvent?.id) return;
        setLoading(true);
        setResults([]);
        // console.log(`${API_URL2}${resultEndpoint}?eventid=${selectedEvent?.id}`)
        const { data } = await apiRequest({ url: `${API_URL2}${resultEndpoint}?eventid=${selectedEvent?.id}` })
        setResults(data);
        setLoading(false);
    }, [apiRequest, selectedEvent?.id]);

    useEffect(() => {
        getResults();
    }, [getResults]);

    return (
        <>
            {/* <ResultsList data={results} loading={loading} /> */}
            <div className=''>
                <div className='mb-4'>
                    <div className='flex gap-4'>
                        <div className="flex items-center">
                            <h2 className='text-2xl font-bold'>Eventos</h2>
                        </div>
                        <TabsResultados data={data} selectedEvent={selectedEvent} loading={loadingEvents} onChange={onChange} onEdit={(eventId) => onEditEvent(eventId)} />
                    </div>
                </div>
                <div className=''>
                    {
                        selectedEvent?.id ? (
                            <ResultsList data={results.filter(x => parseInt(x.position) > 0)} loading={loading} onImportResults={onImportResults} importing={importing} eventId={selectedEvent}/>
                        ) : (
                            <div className='flex items-center justify-center'>
                                <p className='text-gray-500 italic'>Seleccione un evento</p>
                            </div>
                        )
                    }
                </div>
            </div>

            <ModalEvents open={isModalOpen} onClose={closeModal} eventId={selectedEvent?.id} refresh={getResults}/>
        </>
    );
}