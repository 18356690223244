import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL, API_URL3 } from 'constants/api.constant';
import Loading from 'components/Loading';
import useFetch from 'hooks/useFetch';
import TabsResultados from './TabsResultados';
import ResultadosFinalesList from './ResultadosFinalesList';
import { useEffect } from 'react';
import useAxios from 'hooks/useAxios';

const eventsEndpoint = 'v1/external/getevents';
const putResultsEndpoint = 'results';
export default function Resultados() {
  const { id:roundId } = useParams();
  const apiRequest = useAxios();
  const { data, loading } = useFetch(`${API_URL}/${eventsEndpoint}?projectround_id=${roundId}`);
  const [selectedEvent, setSelectedEvent] = useState(data[0]);
  const [saving, setSaving] = useState(false);

  const onSaveResults = async (results) => {
    console.log("results", results);

    const imported = {
      sucess: 0,
      error: 0,
      errors: []
    }
    setSaving(true)
    for (let i = 0; i < results.length; i++) {
      const data = results[i];
      try {
        const config = {
          url: `${API_URL3}/${putResultsEndpoint}/${data.id}`,
          method: 'PUT',
          data
        }

        const resp = await apiRequest(config);
        console.log("resp", resp);
        imported.sucess++;
      } catch (error) {
        console.log("Error element:", data);
        console.error("error", error);
        imported.error++;
        imported.errors.push(
          error.response.data || error.response || error
        );
      }
    }
    setSaving(false)

    alert(`${imported.sucess} resultados importados correctamente.
      \n${imported.error} errores.
      \n${imported.errors.map((error) => `Detalles del error: ${JSON.stringify(error, null, 2)}`).join('\n')}
    `);
  }

  const onChange = (event) => {
    if (event.id === selectedEvent?.id) return;
    setSelectedEvent(event);
  }

  useEffect(() => {
    setSelectedEvent(data[0]);
  }, [data]);

  if (loading) return <Loading />
  return (
    <div>
      <div className=''>
        <div className='mb-4'>
          <div className='flex gap-4'>
            <div className='flex items-center'>
              <h2 className='text-2xl font-bold'>Eventos</h2>
            </div>
            <TabsResultados data={data} selectedEvent={selectedEvent} onChange={onChange} />
          </div>
        </div>
        <div className=''>
          {
            selectedEvent?.id ? (
              <ResultadosFinalesList eventId={selectedEvent?.id} onSave={onSaveResults} saving={saving} />
            ) : (
              <div className='flex items-center justify-center'>
                <p className='text-gray-500 italic'>Seleccione un evento</p>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}