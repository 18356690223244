import { HiSave, HiX } from 'react-icons/hi'
import Modal from './Modal'
import ReactPlayer from 'react-player';
import { useEffect, useState, useRef } from 'react';
import { identifyVideoPlatformAndId, secondsToTwitchTime } from '../utils/twitch'; // Ensure this path is correct

// const getTimeFromUrl = (url) => {
//   if (!url) return 0;
//   const time = url.split('t=')[1];
//   if (!time) return 0;
  
//   console.log( parseInt(time));
//   return parseInt(time);
// }

const formatTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export default function ModalSynctime({ open, onClose, videoData }) {
  const [synctimeActual, setSynctimeActual] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const videoRef = useRef(null); // Correctly declare the ref
  // Determine the platform and format the video URL accordingly
  const [videoStarted, setVideoStarted] = useState(false); // Track if the video has started playing
  const [updateStatus, setUpdateStatus] = useState(null);
  const [updateError, setUpdateError] = useState(null);

  
  useEffect(() => {
    if (open) {
      setSynctimeActual(parseInt(videoData?.Synctime || 0,10));
      setElapsedTime(0);
      setUpdateStatus(null);
      setUpdateError(null);
      setVideoStarted(false);
    }
  }, [open, videoData]);

  const onProgress = (progress) => {
    setElapsedTime(progress.playedSeconds);
  };

  const handlePlay = () => {
    // Only seek to synctimeActual if the video hasn't started yet
    if (!videoStarted && videoRef.current) {
      videoRef.current.seekTo(synctimeActual, 'seconds');
      setVideoStarted(true); // Prevent further seeking by marking the video as started
    }
  };

const handleUpdateClick = async () => {
  setUpdateStatus(null); // Reset the status at the beginning of the operation
  setUpdateError(null); //
    // Format min_time to the appropriate string format if needed
    // Assuming synctimeActual is in seconds and needs to be converted to a string
    const minTime = elapsedTime;
  
    // Construct the body of the PUT request
    const putRequestBody = {
      min_time: String(minTime),
      status: "synced",
    };
  
    try {
      const putResponse = await fetch(`https://api4.gpesportsrd.com/parcferme/${videoData?.parcfermeid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers your API requires
        },
        body: JSON.stringify(putRequestBody),
      });
  
      if (!putResponse.ok) {
        throw new Error(`HTTP error! Status: ${putResponse.status}`);
      }
  
      // Handle PUT response data if needed
      const putResponseData = await putResponse.json();
      console.log('PUT Update successful:', putResponseData);
  
      // Create a new Date object and format it for the POST request
      const currentDateTime = new Date().toISOString();
  
      // Assuming videoData?.Synctime is already an integer, if not, parse it
      const sessionTimeInt = parseInt(videoData?.Synctime, 10);
  
      // Construct the body of the POST request
      const postRequestBody = {
        Fecha: currentDateTime,
        Categoria: videoData?.Categoria,
        Resumen: "Parc Ferme inicio carrera incorrecto",
        videop: videoData?.Youtube1,
        Tiempo: "0",
        Pista: "string", // Replace with actual track name if available
        Lap: "0",
        Reportado: videoData?.nickname,
        Afectado: videoData?.nickname,
        Incidente: "ParcFerme Mal Sincronizado",
        Resolucion: "Warning",
        row_status: "resynced",
        victim_user_id: videoData?.user_id,
        guilty_user_id: videoData?.user_id,
        victim_individual_id: videoData?.individual_id,
        guilty_individual_id: videoData?.individual_id,
        sessionTime: sessionTimeInt, // Send as an integer
        round_id: videoData?.projectround_id,
        project_id: videoData?.projectID,
        incidentes_type: 0,
        fpenalty_type: 0,
        Auth: "syncpenalty"

      };
  
      const postResponse = await fetch('https://api4.gpesportsrd.com/incidentes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers your API requires
        },
        body: JSON.stringify(postRequestBody),
      });
  
      if (!postResponse.ok) {
        throw new Error(`HTTP error! Status: ${postResponse.status}`);
      }
  
      // Handle POST response data if needed
      const postResponseData = await postResponse.json();
      console.log('POST successful:', postResponseData);
      setUpdateStatus('Success');
    
    // Close the modal after a successful operation
    setTimeout(() => onClose(), 2000); // Close the modal after 2 seconds to allow the user to read the success message

    } catch (error) {
      console.error('Request failed:', error);
      setUpdateError(`Update failed: ${error.message}`);

    } finally {
      // Close the modal whether the request succeeded or failed

    }
  };
  
  //  console.log("Video URL:", videoData?.Youtube1);
  //  console.log("Synctime:", synctimeActual); 
  return (
    <Modal open={open} onClose={onClose}>
       {updateStatus && <div className="success-message">{updateStatus}</div>}
    {updateError && <div className="error-message">{updateError}</div>}

      <div className='mb-2'>
        <h2 className='text-2xl font-bold'>Editar Synctime</h2>
        <p className='text-gray-500 text-sm'>Pause el vídeo al momento de las luces de inicio.</p>
      </div>

      <div className=''>
        <label className='text-gray-700'>Usuario:</label>
        <span className='flex items-center gap-1'>
          <p className='text-lg font-semibold'>{videoData?.nickname}</p>
        </span>
      </div>


      <div className="mb-2">
        <ReactPlayer
          ref={videoRef}
          width={'100%'}
          height={300}
          url={videoData?.Youtube1}
          controls
          onProgress={onProgress}
          onPlay={handlePlay}
          playing={open}
          />
      </div>

      <div className='flex gap-2 mb-4'>
        <div>
          <label className='text-gray-700'>Inicio de Carrera:</label>
          <input
            type='text'
            className='w-full border rounded-md px-3 py-2 mt-1 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
            placeholder='Synctime actual'
            value={formatTime(synctimeActual)}
            readOnly
          />
        </div>
        <div>
          <label className='text-gray-700'>Inicio Nuevo:</label>
          <input
            type='text'
            className='w-full border rounded-md px-3 py-2 mt-1 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
            placeholder='Tiempo del video'
            value={formatTime(elapsedTime)}
            readOnly
          />
        </div>
      </div>

      <div className=''>
        <button type='button' onClick={onClose} className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded mr-2'>
          <span className='flex items-center gap-1'>
            <HiX className='text-lg' /> Cancelar
          </span>
        </button>
        <button type='button' onClick={handleUpdateClick} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded'>
        <span className='flex items-center gap-1'>
          <HiSave className='text-lg' /> Actualizar
        </span>
      </button>
      </div>
    </Modal>
  )
}
