import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { AiOutlineFilePdf } from 'react-icons/ai';
import useFetch from 'hooks/useFetch';
import { API_URL, API_URL2 } from 'constants/api.constant';
import IncidentesList from './IncidentesList';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import IncidentesPDF from './IncidentesPDF';
import useAxios from 'hooks/useAxios';
import Loading from 'components/Loading';

const ButtonExportPDF = ({ children }) => (
    <button className='text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 py-2.5 px-5 ml-2'>
        <div className='flex items-center'>
            <AiOutlineFilePdf className='mr-1 text-xl' /> {children}
        </div>
    </button>
);

export default function Incidentes() {
    const navigate = useNavigate();
    const { id } = useParams(); // Round ID
    const round = useOutletContext();
    const apiRequest = useAxios();
    const { data, loading } = useFetch(`${API_URL2}/getincidentes.php?round_id=${id}`);
    const { data: events, loading: loadingEvents } = useFetch(`${API_URL}/v1/external/getevents?projectround_id=${id}`);
    const [resultadosFinales, setResultadosFinales] = useState([]);
    const [loadingResultados, setLoadingResultados] = useState(false);

    const resultsByEventIdEndpoint = 'v1/external/getResultsByEventId'; // Define the endpoint

    const onCreate = () => {
        console.log('onCreate', data);
    };

    const handleImportClick = () => {
        navigate(`/rounds/${id}/incidentes/import`); // Updated navigation path
    };

    useEffect(() => {
        if (events.length > 0) {
            setLoadingResultados(true);
            events.forEach(event => {
                apiRequest({ url: `${API_URL}/${resultsByEventIdEndpoint}/${event.id}` })
                    .then(response => {
                        setResultadosFinales(prevState => [...prevState, { ...event, resultados: response.data }]);
                    })
                    .catch(error => console.log('error', error))
                    .finally(() => setLoadingResultados(false));
            });
        }
    }, [events, apiRequest]);

    if (loading || loadingEvents || loadingResultados) return <Loading />;

    return (
        <>
            <div className='flex justify-between items-center mb-6 overflow-hidden'>
                <h2 className='text-2xl font-bold'>Incidentes</h2>
                <div className='flex items-center'>
                    <button className='text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2' onClick={onCreate}>
                        <div className='flex items-center'>
                            <HiOutlinePlusCircle className='mr-1 text-xl' /> Nuevo Incidente
                        </div>
                    </button>
                    <button className='text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2' onClick={handleImportClick}>
                        Importar Datos
                    </button>
                    <PDFDownloadLink document={<IncidentesPDF data={data} round={round} results={resultadosFinales} />} fileName={`${round.projectname} - ${round.track_name}`}>
                        {({ loading }) => loading ? <ButtonExportPDF>Generando PDF...</ButtonExportPDF> : <ButtonExportPDF>Exportar PDF</ButtonExportPDF>}
                    </PDFDownloadLink>
                </div>
            </div>

            <IncidentesList data={data} loading={loading} />

            {
                data.length > 0 && (
                    <PDFViewer style={{ width: '100%', height: '100vh' }}>
                        <IncidentesPDF data={data} round={round} results={resultadosFinales} />
                    </PDFViewer>
                )
            }
        </>
    );
}
