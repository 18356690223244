import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useFetch(url) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async() => {
        setLoading(true);
        setData([]);
        await axios.get(url)
            .then((res) => setData(res.data))
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }, [url]);

    useEffect(() => {
        url && fetchData();
    }, [fetchData, url]);

    return { data, loading, error, fetchData };
}
