import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_COMPETITIONS, API_penalty_type, API_parcferme_pending, API_pf_optimized,API_incidentes } from 'constants/api.constant';
import useFetch from 'hooks/useFetch';
import Table, { TBody, Td, Th, THead, Tr } from 'components/Table';
import './parcferm.css';
import { HiPencil } from 'react-icons/hi';
import ModalSynctime from 'components/Modalparcfermesync.jsx';
import { fetchTwitchThumbnail, extractVideoId, secondsToTwitchTime } from 'utils/twitch';

function ParcFerm() {
    const { id } = useParams();
    const { data: parcFermePendingData, loading, error } = useFetch(`${API_pf_optimized}?round_id=${id}`);
    const [parcFermePenalties, setParcFermePenalties] = useState([]);
    const [penaltyTypes, setPenaltyTypes] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]); // To keep track of selected rows
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [incidentesData, setIncidentesData] = useState([]);
    const navigate = useNavigate(); // Hook to control navigation
    const [failures, setFailures] = useState(0); // State to track the number of failures
    const [selectedPenaltyTypes, setSelectedPenaltyTypes] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentVideoData, setCurrentVideoData] = useState(null);
    const [videoData, setVideoData] = useState({});

    // Function to open the modal with the specific video data
    const openModal = (item) => {
        setCurrentVideoData(item); // Pass the entire item object to the modal
        setModalOpen(true);
      };
    // Initialize selected penalty types when table data is set
    useEffect(() => {
        const initialPenaltyTypes = tableData.map(item => ({
            rowIndex: tableData.indexOf(item),
            selectedPenaltyType: getParcFermePenalty(item.missing_videos) // Or the default value you need
        }));
        setSelectedPenaltyTypes(initialPenaltyTypes);
    }, [tableData, parcFermePenalties]); 

    useEffect(() => {
        const fetchIncidentesData = async () => {
            try {
                const response = await fetch(`${API_incidentes}?round_id=${id}`);
                const data = await response.json();
                if (data.data) {
                    setIncidentesData(data.data);
                }
            } catch (error) {
                console.error('Error fetching incidentes data:', error);
            }
        };
    
        fetchIncidentesData();
    }, [id]);
    const filteredIncidentes = incidentesData.filter(incident => 
        [115, 116, 117, 118, 119, 120].includes(incident.incidentes_type)
    );

    const isGuilty = (individualId) => {
        return filteredIncidentes.some(incident => incident.guilty_individual_id === individualId);
    };


    useEffect(() => {   
        // Fetch competition data for parc ferme penalties
        const fetchCompetitionData = async () => {
            try {
                const response = await fetch(`${API_COMPETITIONS}?round_id=${id}`);
                const data = await response.json();

                if (data.length > 0 && data[0].parcferme) {
                    setParcFermePenalties(data[0].parcferme.split(',').map(Number));
                }
            } catch (error) {
                console.error('Error fetching competition data:', error);
            }
        };

        fetchCompetitionData();
    }, [id]);

    useEffect(() => {
        // Fetch penalty types data
        const fetchPenaltyTypes = async () => {
            try {
                const response = await fetch(API_penalty_type);
                const data = await response.json();
                setPenaltyTypes(data);
            } catch (error) {
                console.error('Error fetching penalty types:', error);
            }console.log("Penalty Types:", penaltyTypes.data);
        };

        fetchPenaltyTypes();
    }, []);

    useEffect(() => {
        if (parcFermePendingData && parcFermePendingData.data) {
            // Sort parc ferme pending data by rank
            const sortedData = parcFermePendingData.data.sort((a, b) => a.rank - b.rank);

            // Automatically select rows with missing YouTube links
            const newSelectedRows = sortedData.reduce((acc, item, index) => {
                if (!item.Youtube1) {
                    acc.push(index);
                }
                return acc;
            }, []);

            setTableData(sortedData);
            setSelectedRows(newSelectedRows);
        }
    }, [parcFermePendingData]);
    useEffect(() => {
        const loadVideoData = async () => {
          const newVideoData = {};
      
          for (const item of tableData) {
            if (item.Youtube1) {
              // Assuming 'Synctime' is in hours and needs to be formatted for Twitch
              newVideoData[item.Youtube1] = await getVideoThumbnail(item.Youtube1, item.Synctime);
            }
          }
      
          setVideoData(newVideoData);
        };
      
        loadVideoData();
      }, [tableData]);
      
    const getParcFermePenalty = (missingVideos) => {
        return parcFermePenalties[missingVideos - 1] || 'N/A';
    };
    const getIncidentType = (missingVideos) => {
        const incidentTypes = [115, 116, 117, 118, 119, 120];
        return incidentTypes[missingVideos - 1] || 115;
    };

    const handleDropdownChange = (index, selectedValue) => {
        const updatedPenaltyTypes = selectedPenaltyTypes.map(penaltyType => {
            if (penaltyType.rowIndex === index) {
                return { ...penaltyType, selectedPenaltyType: selectedValue };
            }
            return penaltyType;
        });
        setSelectedPenaltyTypes(updatedPenaltyTypes);
    };

    const handleImportButtonClick = async () => {
        let successes = 0;
        let failures = 0;
        const rowsToProcess = selectedRows.filter(rowIndex => {
            const item = tableData[rowIndex];
            return !isGuilty(item.individual_id);
        });
    
        // Prepare and send POST requests for filtered rows
        for (const rowIndex of rowsToProcess) {
 
            const item = tableData[rowIndex];

            // Retrieve the selected penalty type for this row
            const selectedPenaltyType = selectedPenaltyTypes.find(p => p.rowIndex === rowIndex)?.selectedPenaltyType;
            const selectedPenaltyTypeNumber = parseInt(selectedPenaltyType, 10);

            const penaltyTypeText = penaltyTypes.find((type) => type.id === selectedPenaltyTypeNumber);

    
            // Debugging: Log the penalty type text
            console.log(`Penalty Type Text for ${selectedPenaltyType}:`, penaltyTypeText);
    
            if (!penaltyTypeText) {
                console.error(`No matching penalty type found for ID: ${selectedPenaltyType}`);
            }


            const postData = {
                Fecha: new Date().toISOString(),
                Categoria: item.Categoria,
                Resumen: `Parcferme (${item.missing_videos})`,
                videop: 'youtube.com/gpesportsrd/parcferme',
                Lap: '0',
                Reportado: item.nickname,
                Afectado: item.nickname,
                Incidente: `Parcferme (${item.missing_videos})`,
                Resolucion: penaltyTypeText ? penaltyTypeText.text : 'Unknown Penalty', // Use the found text or a default value
                Auth: 'AutoPF',
                victim_user_id: item.user_id,
                guilty_user_id: item.user_id,
                victim_individual_id: item.individual_id,
                guilty_individual_id: item.individual_id,
                sessionTime: 1,
                round_id: item.projectround_id,
                project_id: item.projectID,
                incidentes_type: getIncidentType(item.missing_videos),
                fpenalty_type: parseInt(selectedPenaltyType, 10) 

            };

            try {
                const response = await fetch(API_incidentes, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData),
                });

                if (response.ok) {
                    successes++;
                } else {
                    failures++;
                }
            } catch (error) {
                failures++;
                setFailures(failures);
            }
        }

    //    setConfirmationMessage(`Import completed. Successes: ${successes}, Failures: ${failures}`);
    //    setShowConfirmation(true);
    setShowModal(true);
    setConfirmationMessage(`Import completed. Successes: ${successes}, Failures: ${failures}`);

    };
    const handleModalDismiss = () => {
        setShowModal(false);
        // If there are no failures, navigate to /incidentes
        if (failures === 0) {
            navigate(`/rounds/${id}/incidentes`);
        } else {
            // If there are failures, refresh the page
            window.location.reload();
        }
    };
    const getVideoThumbnail = async (url, synctime) => {
        const youtubePattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w\-_]+)(?:&|$)/;
        const twitchPattern = /^(?:https?:\/\/)?(?:www\.)?twitch\.tv\/videos\/(\d+)/;
        let hyperlink;
        let thumbnail;
      
        if (youtubePattern.test(url)) {
          const videoId = url.match(youtubePattern)[1];
          hyperlink = `${url}&t=${synctime}`;
          thumbnail = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
        } else if (twitchPattern.test(url)) {
          const videoId = extractVideoId(url);
          if (videoId) {
            thumbnail = await fetchTwitchThumbnail(videoId);
            // Twitch uses the format 't=1h2m3s' for timestamps
            const twitchTime = synctime ? `?t=${secondsToTwitchTime(synctime)}` : ''; // Convert seconds to Twitch format
            hyperlink = `https://www.twitch.tv/videos/${videoId}${twitchTime}`;
          }
        }
      
        return { hyperlink, thumbnail };
      };
      
    
    const getYoutubeThumbnail = (url) => {
        const urlObj = new URL(url);
        let videoId;
    
        if (urlObj.hostname === 'youtu.be') {
            videoId = urlObj.pathname.substring(1);
        } else if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtube.com') {
            videoId = urlObj.searchParams.get('v');
        }
    
        if (videoId) {
            return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
        }
    
        return null;
    };
    const handleCheckboxChange = (index) => {
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setSelectedRows([...selectedRows, index]);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data</p>;

    return (
        <>
            {showConfirmation && (
                <div className="confirmation-message">
                    <p>{confirmationMessage}</p>
                    <button onClick={() => setShowConfirmation(false)}>Dismiss</button>
                </div>
            )}
            <button onClick={handleImportButtonClick} className='bg-blue-500 text-white py-2 px-4 mt-4 mb-2'>
                Importar Penalidades
            </button>

            <Table id='table-parc-ferme'>
                <THead>
                    <Tr>
                        <Th></Th> {/* Checkbox */}
                        <Th>Rank</Th>
                        <Th>Nickname</Th>
                        <Th>Youtube Link</Th>
                        <Td>Sync Time</Td>
                        <Th>Missing Videos</Th>
                        <Th>Penalty Type</Th>
                    </Tr>
                </THead>
                <TBody>
                    {tableData.map((item, index) => {
                        const isYoutubeLinkMissing = !item.Youtube1;
                        const rowClass = isYoutubeLinkMissing ? 'highlight-row' : ''; // Or use Tailwind CSS class
                        const guilty = isGuilty(item.individual_id);
                        const videoInfo = videoData[item.Youtube1];
                        return (
                            <Tr key={index} className={rowClass}>
                                <Td>
                                    <input
                                        type="checkbox"
                                        checked={!guilty && selectedRows.includes(index)}
                                        onChange={() => handleCheckboxChange(index)}
                                        readOnly={guilty}
                                    />
                                </Td>
                                <Td>{item.rank}</Td>
                                <Td>{item.nickname}</Td>
                                <Td>
        {videoInfo ? (
          <a href={videoInfo.hyperlink} target="_blank" rel="noopener noreferrer">
            <img 
              src={videoInfo.thumbnail} 
              alt="Thumbnail" 
              style={{ maxHeight: '100px' }} 
            />
          </a>
        ) : (
          'None'
        )}
      </Td>
                {item.Synctime}  <a className="pencil-link" onClick={() => openModal(item)}>
    <HiPencil />
  </a>
                <Td>{item.missing_videos}</Td>
                                <Td>
                                <select
    value={selectedPenaltyTypes.find(p => p.rowIndex === index)?.selectedPenaltyType || ''}
    onChange={(e) => handleDropdownChange(index, e.target.value)}
>
                                        <option value="">Select Penalty Type</option>
                                        {penaltyTypes.map((penaltyType) => (
                                            <option key={penaltyType.id} value={penaltyType.id}>
                                                {penaltyType.text}
                                            </option>
                                        ))}
                                    </select>
                                </Td>
                            </Tr>
                        );
                    })}
                </TBody>
            </Table>      <ModalSynctime
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        videoData={currentVideoData}
      />
            {showModal && (
                <div className="modal-backdrop">
                    <div className="modal">
                        <p>{confirmationMessage}</p>
                        <button onClick={handleModalDismiss} className="button-dismiss">Continuar</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ParcFerm;
