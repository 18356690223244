import React from 'react'
import Table, { LoadingTableContent, NoDataRow, TBody, THead, Td, Th, Tr } from 'components/Table'
import { Link, useLocation } from 'react-router-dom'

export default function RoundList({ data, loading }) {
  const location = useLocation();
  return (
    <Table>
      <THead>
        <Tr>
          <Th style={{ padding: 0}}></Th>
          <Th>Fecha</Th>
          <Th>Categoria</Th>
          <Th>Track Name</Th>
        </Tr>
      </THead>
      <TBody>
        <LoadingTableContent colSpan='4' loading={loading} />
        {data.map((round, index) => (
          <Tr className='cursor-pointer hover:bg-gray-100 relative' key={index}>
            <Th style={{ padding: 0}}>
              <Link to={`${location.pathname}/${round.round_id}`} className='absolute inset-0 z-10' />
            </Th>
            <Td>{new Date(round.start_date).toLocaleString()}</Td>
            <Td>{round.projectname}</Td>
            <Td>{round.track_name}</Td>
          </Tr>
        ))}
        <NoDataRow show={!loading && data.length === 0} colSpan='4' />
      </TBody>
    </Table>
  )
}
