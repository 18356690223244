import React from 'react'
import { navigation } from 'config/nav.config'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

export default function Menu({ className }) {
    const location = useLocation()

    const isActive = (path) => {
        return location.pathname === path
    }

    return (
        <ul className={classNames(className)}>
                {
                    navigation.map(route => (
                        <li key={route.key}>
                            <Link
                                to={route.path}
                                className={classNames(
                                    'block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0',
                                    {'text-gray-700': !isActive(route.path)},
                                    {'text-blue-700': isActive(route.path)}
                                )}
                            >
                                {route.label}
                            </Link>
                        </li>
                    ))
                }
        </ul>
    )
}
