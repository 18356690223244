//export const API_URL = "http://20.121.40.254:1337/api";
export const API_URL = "https://restapi.gpesportsrd.com/api";
export const API_URL2 = "https://gpesportsrd.com/gpt/api";
export const API_URL3 = "https://api4.gpesportsrd.com";
export const URL4 = "https://api4.gpesportsrd.com";
export const API_parcferme = "https://api4.gpesportsrd.com/parcferme";
export const API_incidentes = "https://api4.gpesportsrd.com/incidentes";
export const API_participants = "https://api4.gpesportsrd.com/consultaParticipants";
export const API_preincidentes = "https://api4.gpesportsrd.com/preincidents"; 
export const API_rounddetails = "https://restapi.gpesportsrd.com/api/v1/external/getrounds"; 
// Add these lines to your existing constants file
export const API_COMPETITIONS = "https://api4.gpesportsrd.com/competitions";
export const API_LAST_ROUNDS = "https://api4.gpesportsrd.com/lastrounds";
export const API_ROUND_DETAILS = "https://api4.gpesportsrd.com/consultaRounds";
export const API_seasonstatus = "https://api4.gpesportsrd.com/season_status";
export const API_parcferme_seasonstatus = "https://api4.gpesportsrd.com/season_status";
export const API_parcferme_pending = "https://api4.gpesportsrd.com/parcferme_pending?projectround_id";
export const API_pf_optimized = "https://api4.gpesportsrd.com/parcferme_pending/optimized";

export const API_incident_type = "https://api4.gpesportsrd.com/types/incidentes";
export const API_penalty_type = "https://api4.gpesportsrd.com/types/penalty";
export const API_preraceresult = "https://api4.gpesportsrd.com/preraceresult"
export const csvupload = "upload-csv/iracing"