import { API_URL2,API_incidentes } from 'constants/api.constant';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Videos from './Videos';
import Votos from './Votos';
import { HiChevronLeft } from 'react-icons/hi2';
import SelectPenaltyType from './SelectPenaltyType';
import useAxios from 'hooks/useAxios';
import axios from 'axios';
//import * as API from './constants/api.constant';


const incidentesEndpoint = '/getincidentes.php';
const votosPostEndpoint = '/votes.php';


const formatSessionTime = (sessionTime) => {
    const totalSeconds = Number(sessionTime);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
};
export default function IncidentesDetail() {
    const { incidenteId } = useParams();
    const nav = useNavigate();
    const apiRequest = useAxios();
    const { data, loading, fetchData } = useFetch(`${API_URL2}/${incidentesEndpoint}?id=${incidenteId}`);
    const [penalty, setPenalty] = useState(null);
    const [sessionTimeFormatted, setSessionTimeFormatted] = useState('00:00.000'); // Correct placement inside the component

    const handleVisibilityChange = async () => {
        const newVisibility = parseInt(data[0].visibility, 10) === 1 ? 0 : 1;

        const config = {
            method: 'put',
            url: `${API_incidentes}/${data[0].id}`, // Assuming this is the endpoint for updating visibility
            data: {
                visibility: newVisibility,
            },
        };

        try {
            const response = await apiRequest(config);
            if (response.status === 200) { // Assuming 200 is the success status code
                fetchData(`${API_URL2}/${incidentesEndpoint}?id=${incidenteId}`); // Refresh data
                alert(`Incidente ${newVisibility === 1 ? 'restaurado' : 'eliminado'} correctamente`);
            }
        } catch (error) {
            alert(`Error al ${newVisibility === 1 ? 'restaurar' : 'eliminar'} el incidente \n${error?.response?.data?.error || error.toString()}`);
        }
    };


    const onChangePenalty = async(penalty) => {
        setPenalty(penalty);
    }

    const onSavePenalty = async() => {
        if (!penalty) {
            alert('Debes seleccionar una resolucion');
            return;
        }

        const formData =  {
            incidenteid: data[0].id,
            penaltyid: penalty.value,
            penaltydescription: penalty.label,
            individualid: data[0].guilty_id,
            isfinal: 1,
        }

        const config = {
            method: 'post',
            url: `${API_URL2}${votosPostEndpoint}`,
            data: formData,
        }

        try {
            const response = await apiRequest(config);
            if (response.status === 201) {
                setPenalty(null);
                fetchData(`${API_URL2}/${incidentesEndpoint}?id=${incidenteId}`);
                alert('Voto creado correctamente');
            }
            
        } catch (error) {
            alert(`Error al crear el voto \n${error?.response?.data?.error || error.toString()}`);
        }
    }

    useEffect(() => {
        if (data.length > 0) {
            const formattedTime = data[0].sessionTime ? formatSessionTime(data[0].sessionTime) : '00:00.000';
            setSessionTimeFormatted(formattedTime); // Update state with the formatted time
            if (data[0].fpenalty_type) {
                setPenalty({ 
                    value: parseInt(data[0].fpenalty_type), 
                    label: data[0].Resolucion 
                });
            }
        }
    }, [data]);

    if (loading) return <p>Loading...</p>
  //  const [data, setData] = useState([incidente]); // Initialize `data` with `incidente` as the first item.

    // Function to handle "Reverse" button click
    const handleReverseClick = async () => {
      // Prepare the payload using `data[0]`
      const payload = {
        "Fecha": new Date().toISOString(),
        "Categoria": data[0].Categoria,
        "Resumen": `(INVERTIDO)${data[0].Resumen}`,
        "videop": data[0].videop,
        "Tiempo": data[0].Tiempo,
        "Pista": data[0].Pista,
        "Lap": data[0].Lap,
        "Reportado": data[0].Afectado,
        "Afectado": data[0].Reportado,
        "Incidente": data[0].Incidente,
        "Auth": "invertincident",
        "row_status": data[0].id,
        "row_status_description": `invertincident.${data[0].id}`,
        "victim_user_id": parseInt(data[0].guilty_id), // Convert to integer
        "guilty_user_id": parseInt(data[0].victim_id),
        "sessionTime": 10,
        "round_id": parseInt(data[0].round_id),
        "trackid": parseInt(data[0].trackid),
        "project_id": parseInt(data[0].project_id),
        "incidentes_type": parseInt(data[0].incidentes_type),
      };
  
      try {
        // Perform the POST request
        const response = await axios.post('https://api4.gpesportsrd.com/incidentes', payload, {
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers required by the API
          },
        });
  
        // Handle response here, e.g., showing a success message
        console.log('Reverse incident response:', response.data);
        alert('Incident reversed successfully!');
      } catch (error) {
        // Handle error here, e.g., showing an error message
        console.error('Error reversing incident:', error);
        alert('Failed to reverse the incident.');
      }
    };

    return (
        <>
            <div className='flex justify-between items-end mb-6 overflow-hidden'>
                <div className='flex text-2xl font-bold cursor-pointer' onClick={() => nav(-1)} >
                    <HiChevronLeft className=' self-center'/>
                    <h2 className=''>Incidente {incidenteId}</h2>
                </div>
            </div>

            {
                data.length === 0 ? (
                    <div className='flex justify-center'>
                        <p className='text-gray-500 italic'>No se encontró el incidente</p>
                    </div>
                ) :
                (
                    <>
                        <Videos className='mb-6' incidente={data[0]} />
                        <div className='grid grid-cols-6 gap-10 mb-6'>
                            <div className='col-span-6 md:col-span-3 lg:col-span-2'>
                            <div className='shadow-md sm:rounded-lg px-6 py-3'>
                                <div>
                                    <div className='py-2'>
                                        <h3 className='font-bold text-2xl mb-2'>
                                            {data[0].Incidente}
                                        </h3>
                                        <h2 className='font-bold text-2xl mb-2'>
                                            Lap {data[0].Lap} | {sessionTimeFormatted}
                                        </h2>


                                        <p className='text-sm font-medium text-gray-700 mb-2'>
                                            {data[0].Resumen}
                                        </p>

                                        <div className='mb-2'>
                                            <label className='text-sm font-medium text-gray-700'>Resolucion</label>
                                            <SelectPenaltyType
                                                placeholder='Resolucion'
                                                value={penalty?.value}
                                                onChange={onChangePenalty}
                                            />
                                            <button onClick={onSavePenalty} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2'>
                                                Guardar
                                            </button>      <button onClick={handleReverseClick} style={{ margin: '20px', padding: '10px 20px', backgroundColor: '#007bff', color: 'white', borderRadius: '5px', cursor: 'pointer' }}>
        Reverse
      </button>                    <button
                        onClick={handleVisibilityChange}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
                    >
                        {parseInt(data[0].visibility, 10) === 1 ? 'Eliminar' : 'Restaurar'}
                    </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className='col-span-6 md:col-span-3 lg:col-span-4'>
                                <Votos incidente={data[0]} />
                            </div>
                        </div>     
                    </>

                )
            }

            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </>
    )
}