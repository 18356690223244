export const navigation = [
    {
        key: 'home',
        path: '/',
        label: 'Home',
    },
    {
        key: 'rounds',
        path: '/rounds',
        label: 'Rondas',
    },
];

export const roundsNavigation = [
    { key: 'rounds', path: '/rounds/:id', label: 'Home'},
    { key: 'importresults' , path: '/rounds/:id/importresults', label: 'Importar Resultados' },
    { key: 'parcferm' , path: '/rounds/:id/parcferm', label: 'Parc Ferme' },
    { key: 'incidentes' , path: '/rounds/:id/incidentes', label: 'Incidentes' },
    { key: 'resultados' , path: '/rounds/:id/resultados', label: 'Resultados' },
    { key: 'grs' , path: '/rounds/:id/grs', label: 'Redes Sociales' },
    { key: 'SeasonStatus' , path: '/rounds/:id/SeasonStatus', label: 'Season Status' },
];
