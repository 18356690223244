import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import EventsList from '../Home/EventsList';
import { useRoundDetails } from 'utils/rounddetails';
import { useTable, useSortBy } from 'react-table';
import { sendWhatsAppMessage } from 'utils/whatsapp'; // Import the sendWhatsAppMessage function
import './table.css';

function formatDate(dateString) {
  const options = { weekday: 'short', day: '2-digit', month: 'short' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function formatPercentage(value) {
  return `${Math.round(value)}%`;
}

export default function RoundsHome() {
  const { id } = useParams();
  const { round } = useRoundDetails(id);

  const [seasonStatus, setSeasonStatus] = useState([]);
  const [loadingSeasonStatus, setLoadingSeasonStatus] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleParcFermeClick = async () => {
    try {
      // Loop through selected rows and send WhatsApp message for each user
      for (const rowId of selectedRows) {
        const user = seasonStatus.find((item) => item.id === rowId);
        if (user) {
          const message = `Veo que de las ${user.results} carreras que llevas en ${user.Categoria}, solo has enviado ${user.videos} videos, si tu porcentaje (${user.parcferme_percentage}%) sigue cayendo puedes ser expulsado de la categoría. ¿Necesitas ayuda para transmitir o enviar tus videos?`;
          await sendWhatsAppMessage(user.user_id, user.individual_id, message);
        }
      }

      // Handle success
      console.log('Parc Ferme messages sent successfully');
    } catch (error) {
      console.error('Error sending Parc Ferme messages:', error);
    }
  };

  const handleLastRaceClick = async () => {
    try {
      // Loop through selected rows and send WhatsApp message for each user
      for (const rowId of selectedRows) {
        const user = seasonStatus.find((item) => item.id === rowId);
        if (user) {
          const message = `Veo que no corres desde ${user.last_trackname} en ${user.Categoria}, hace ${user.days_since_last_start} días. Pasa algo por lo que no estás corriendo?`;
          await sendWhatsAppMessage(user.user_id, user.individual_id, message);
        }
      }

      // Handle success
      console.log('Last Race messages sent successfully');
    } catch (error) {
      console.error('Error sending Last Race messages:', error);
    }
  };

  useEffect(() => {
    if (round) {
      async function fetchSeasonStatus() {
        try {
          const response = await fetch(`https://api4.gpesportsrd.com/season_status?projectID=${round.project_id}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setSeasonStatus(data);
          setLoadingSeasonStatus(false);
        } catch (error) {
          console.error('Error fetching season status:', error);
          setLoadingSeasonStatus(false);
        }
      }

      fetchSeasonStatus();
    }
  }, [round]);

  const columns = React.useMemo(
    () => [
      { Header: 'Nickname', accessor: 'nickname' },
      { Header: 'Last Start Date', accessor: 'last_start_date', Cell: ({ value }) => formatDate(value) },
      {
        Header: 'Last Track Name',
        accessor: 'last_trackname',
        Cell: ({ cell }) => (
          <Link to={`/rounds/${cell.row.original.last_projectround_id}`}>{cell.row.original.last_trackname}</Link>
        ),
      },
      { Header: 'Race Count', accessor: 'race_count' },
      { Header: 'Attendance', accessor: 'attendance_percentage', Cell: ({ value }) => formatPercentage(value) },
      { Header: 'PF Pending', accessor: 'pf_pending' },
      { Header: 'Parc Ferme', accessor: 'parcferme_percentage', Cell: ({ value }) => formatPercentage(value) },
      { Header: 'Days Ago', accessor: 'days_since_last_start' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: seasonStatus,
      initialState: {
        selectedRowIds: selectedRows.reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {}),
      },
      getToggleHideAllColumnsProps: () => {},
    },
    useSortBy
  );

  const toggleRowSelection = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        // If the rowId is already in selectedRows, remove it
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        // If the rowId is not in selectedRows, add it
        return [...prevSelectedRows, rowId];
      }
    });
  };

  return (
    <>
      <EventsList roundId={id} />
      <h2>Season Status</h2>
      {loadingSeasonStatus ? (
        <p>Loading season status...</p>
      ) : (
        <>
          <div className="table-toolbar">
            <button className="table-button" onClick={handleParcFermeClick}>
              Parc Ferme
            </button>
            <button className="table-button" onClick={handleLastRaceClick}>
              Last Race
            </button>
          </div>
          <table {...getTableProps()}>
          <thead>
  {headerGroups.map((headerGroup) => (
    <tr {...headerGroup.getHeaderGroupProps()}>
<th>
  <input
    type="checkbox"
    onChange={(e) => {
      const allRowIds = rows.map((row) => row.original.id);
      setSelectedRows(e.target.checked ? allRowIds : []);
    }}
    checked={rows.length > 0 && selectedRows.length === rows.length}
  />
</th>
      {headerGroup.headers.map((column) => (
        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
          {column.render('Header')}
          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
        </th>
      ))}
    </tr>
  ))}
</thead>
<tbody {...getTableBodyProps()}>
  {rows.map((row) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        <td>
          <input
            type="checkbox"
            checked={selectedRows.includes(row.original.id)}
            onChange={() => toggleRowSelection(row.original.id)}
          />
        </td>
        {row.cells.map((cell) => {
          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
        })}
      </tr>
    );
  })}
</tbody>
          </table>
        </>
      )}
    </>
  );
}
